import React from "react";
import styled from "styled-components";
import { useLocation } from "react-router-dom";

type GlobalDashboardButtonProps = {
  id: number;
  url: string;
  subscribed: boolean;
  global_dashboard?: number;
  c_report_id?: number;
};
interface TipProps {
  bottom: number;
  right: number;
  width: number;
}

const DashboardButton: React.FC<GlobalDashboardButtonProps> = ({
  id,
  url,
  subscribed,
  global_dashboard,
  c_report_id,
}) => {
  const location = useLocation();

  if (c_report_id) {
    subscribed = true;
  }

  function generateHref() {
    if (c_report_id) {
      return `/reports/global-dashboard/medcore/${c_report_id}/${url}`;
    }

    return `/reports/global-dashboard/${id}/${url}`;
  }

  if (
    location.pathname.includes("catalog") ||
    location.pathname.includes("reports")
  ) {
    if (global_dashboard === 1) {
      if (subscribed) {
        return (
          <CustomButton href={generateHref()} target="_blank">
            Dashboard
          </CustomButton>
        );
      } else {
        return (
          <CustomButton>
            Dashboard
            <TextSpan bottom={-75} right={-30} width={164}>
              Subscribe to access dashboard
            </TextSpan>
          </CustomButton>
        );
      }
    } else {
      return null;
    }
  } else if (location.pathname.includes("medsku")) {
    if (subscribed) {
      return (
        <CustomButton href={`/medsku/dashboard/${id}/${url}`} target="_blank">
          Dashboard
        </CustomButton>
      );
    } else {
      return (
        <CustomButton>
          Dashboard
          <TextSpan bottom={-75} right={-30} width={164}>
            Subscribe to access dashboard
          </TextSpan>
        </CustomButton>
      );
    }
  } else if (location.pathname.includes("pt")) {
    if (subscribed) {
      if (url == "us-transcatheter-aortic-valve-replacement-market---medaccess") {
        return (
          <CustomButton href={`/medaccess/dashboard/${id}/${url}`} target="_blank">
            Dashboard
          </CustomButton>
        );
      }
      return (
        <CustomButton href={`/pt/dashboard/${id}/${url}`} target="_blank">
          Dashboard
        </CustomButton>
      );
    } else {
      return (
        <CustomButton>
          Dashboard
          <TextSpan bottom={-75} right={-30} width={164}>
            Subscribe to access dashboard
          </TextSpan>
        </CustomButton>
      );
    }
  } else if (location.pathname.includes("survey")) {
    if (subscribed) {
      return (
        <CustomButton href={`/survey/dashboard/${id}/${url}`} target="_blank">
          Dashboard
        </CustomButton>
      );
    } else {
      return (
        <CustomButton>
          Dashboard
          <TextSpan bottom={-75} right={-30} width={164}>
            Subscribe to access dashboard
          </TextSpan>
        </CustomButton>
      );
    }
  } else {
    return null;
  }
};

const CustomButton = styled.a`
  display: block;
  align-items: center;
  background-color: transparent;
  border: 1px solid #5dc097;
  color: #5dc097;
  padding: 10px 2rem;
  border-radius: 5rem;
  position: relative;
  font-size: 1.8rem;

  &:hover span {
    visibility: visible;
  }

  &:hover {
    background-color: #5dc097;
    color: #fff;
  }

  // @media screen and (max-width: 675px) {
  //   margin-top: 1rem;
  // }
`;

const TextSpan = styled.span<TipProps>`
  font-size: 2rem;
  visibility: hidden;
  background-color: ${(props) => props.theme.colors.idataBlue};
  color: white;
  padding: 10px;
  border-radius: 5px;
  width: ${(props) => props.width}px;

  position: absolute;
  z-index: 1;
  bottom: ${(props) => props.bottom}px;
  right: ${(props) => props.right}px;
`;

export default DashboardButton;
